<template>
  <section>
    <div class="row">
      <div
        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3"
      >
        <div class="table-box p-3">
          <data-table
            :options="dataList.options"
            :data-set="data.dataList"
            :page-size="data.pageSize"
            @change-action="changeAction"
            @pagination="pagination"
            @limit-change="limitChange"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { useRoute } from "vue-router";
import { onMounted, reactive } from "vue";
import DataTable from "@/common/components/datatable";
import HelperFunction from "@/common/helpers";
import { useStore } from "vuex";

export default {
  name: "GlobalDocuments",
  components: { DataTable },
  setup() {
    const route = useRoute();
    const {
      data,
      getAllData,
      openModal,
      selectedCompany,
      storeFormData,
      deleteListData,
      permissionResolver,
    } = HelperFunction();

    /** watch **/
    permissionResolver(route.path);

    let dataList = reactive({
      isModalActive: false,
      params: {
        page: null,
        limit: null,
        sort: "desc",
        search: "",
        company_id: null,
      },
      formError: {},
      formData: {},
      options: {
        name: "Global Documents",
        columns: [
          {
            title: "Name",
            type: "link",
            key: "name",
            tooltip: true,
            truncate: true,
            modifier: (column, row) => {
              return `/library/update-template/${row.id}`;
            },
          },
          {
            title: "Status",
            type: "html",
            key: "status",
            modifier: (column, row) => {
              let output = `<div class="btn btn-purple rounded-pill">${column}</div>`;
              if (column === "Submitted for review") {
                output = `<div class="btn btn-light-green rounded-pill">${column}</div>`;
              } else if (column === "Draft document") {
                output = `<div class="btn btn-pink rounded-pill">${column}</div>`;
              }
              return output;
            },
          },
        ],
        showAction: false,
      },
    });

    /** mounted**/
    onMounted(() => {
      getData();
      $("#showModal").on("hidden.bs.modal", (e) => {
        dataList.isModalActive = false;
        dataList.formData = {};
      });
    });
    /** mounted end**/
    const getData = () => {
      data.setURL = vueConfig.Library.globalContentItemsEndPoint;
      dataList.params.company_id = selectedCompany.value.id
        ? selectedCompany.value.id
        : null;
      getAllData(data.setURL, dataList.params);
    };

    const changeAction = async (action, row) => {
      if (action.type === "edit") {
        modalOpen();
        dataList.formData = row;
      } else if (action.type === "delete") {
        await deleteListData(
          `${vueConfig.Library.globalContentItemsEndPoint}/${row.id}`
        ).then(() => {
          getData();
        });
      }
    };
    const pagination = (page) => {
      dataList.params.page = page;
      getData();
    };
    const limitChange = (limit) => {
      dataList.params.limit = limit;
      delete dataList.params.page;
      getData();
    };
    const submitForm = (formData) => {
      const action = {
        url: formData.id
          ? `${vueConfig.Library.globalContentItemsEndPoint}/${formData.id}`
          : vueConfig.Library.globalContentItemsEndPoint,
        modalId: "showModal",
      };
      const submitData = {
        ...formData,
        company_id: selectedCompany.value.id ? selectedCompany.value.id : null,
        type: "new",
        category: 1,
      };
      storeFormData(action, submitData)
        .then((response) => {
          getData();
          console.log(response, "response");
        })
        .catch((error) => {
          if (error.response) {
            dataList.formError = error.response.data.errors;
          }
        });
    };

    const modalOpen = () => {
      dataList.isModalActive = true;
      openModal("showModal");
    };

    function search() {
      getData();
    }
    /** watch end **/
    function getFilteredValue(params) {
      console.log(params);
    }

    return {
      data,
      pagination,
      limitChange,
      changeAction,
      dataList,
      search,
      getFilteredValue,
      modalOpen,
      submitForm,
    };
  },
};
</script>

<style scoped></style>
